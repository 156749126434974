import React, { FunctionComponent } from "react";

import SEO from "../components/Seo";
import { DefaultLayout } from "../components/DefaultLayout";
import { ProjectList } from "../components/ProjectList";

const Projects: FunctionComponent = () => {
  return (
    <>
      <SEO title="Projekte" />
      <DefaultLayout>
        <ProjectList />
      </DefaultLayout>
    </>
  );
};

export default Projects;
