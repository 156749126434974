import React, { FunctionComponent } from "react";
import { List } from "antd";
import Img, { FluidObject } from "gatsby-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";

type Projects = {
  allMdx: {
    edges: {
      node: {
        id: string;
        fields: {
          slug: string;
        };
        frontmatter: {
          title: string;
          image?: {
            childImageSharp: {
              fluid: FluidObject;
            };
          };
        };
      };
    }[];
  };
};

const StyledListItem = styled(List.Item)`
  margin: 2rem 0;
`;

export const ProjectList: FunctionComponent = () => {
  const result: Projects = useStaticQuery(graphql`
    query {
      allMdx(filter: { fileAbsolutePath: { regex: "content/projects/i" } }) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 2048) {
                    src
                    srcSet
                    sizes
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <List
      dataSource={result.allMdx.edges ?? []}
      grid={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2, gutter: 32 }}
      style={{ width: "70%" }}
      renderItem={({ node: { fields, frontmatter } }) => {
        return frontmatter.image ? (
          <StyledListItem>
            <Link to={fields.slug}>
              <Img
                fluid={frontmatter.image.childImageSharp.fluid}
                alt={`Projekt ${fields.slug}`}
                style={{ maxHeight: "30rem" }}
              />
            </Link>
          </StyledListItem>
        ) : null;
      }}
    />
  );
};
